// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-background-detail-page-js": () => import("./../../../src/templates/background-detail-page.js" /* webpackChunkName: "component---src-templates-background-detail-page-js" */),
  "component---src-templates-background-page-js": () => import("./../../../src/templates/background-page.js" /* webpackChunkName: "component---src-templates-background-page-js" */),
  "component---src-templates-bookmark-page-js": () => import("./../../../src/templates/bookmark-page.js" /* webpackChunkName: "component---src-templates-bookmark-page-js" */),
  "component---src-templates-detail-page-js": () => import("./../../../src/templates/detail-page.js" /* webpackChunkName: "component---src-templates-detail-page-js" */),
  "component---src-templates-lexicon-detail-page-js": () => import("./../../../src/templates/lexicon-detail-page.js" /* webpackChunkName: "component---src-templates-lexicon-detail-page-js" */),
  "component---src-templates-lexicon-page-js": () => import("./../../../src/templates/lexicon-page.js" /* webpackChunkName: "component---src-templates-lexicon-page-js" */),
  "component---src-templates-list-page-js": () => import("./../../../src/templates/list-page.js" /* webpackChunkName: "component---src-templates-list-page-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("./../../../src/templates/privacy-policy-page.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-search-page-js": () => import("./../../../src/templates/search-page.js" /* webpackChunkName: "component---src-templates-search-page-js" */),
  "component---src-templates-sublist-page-js": () => import("./../../../src/templates/sublist-page.js" /* webpackChunkName: "component---src-templates-sublist-page-js" */)
}

