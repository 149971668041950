module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/hlv-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e4cb052c6ae2a34acddc038710ada901"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"remarkPlugins":[[null,{"target":"_blank","content":{"type":"element","tagName":"i","children":[{"type":"text","value":" (öffnet ein neues Fenster)"}]},"contentProperties":{"class":"sr-only"}}]],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"C:\\Users\\ayildirim\\Documents\\projects\\hlv"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/quiz","/lexikon/*","/list/*","/list/*/*","/hintergrund/*","/datenschutzerklaerung","/impressum","/suche","/gemerkt"],"runtimeCaching":[{"urlPattern":{},"handler":"StaleWhileRevalidate"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
